/* src/Careers.css */
.careers {
  margin: 80px 0 0 0;
  padding: 100px 20px;
  text-align: center;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s ease, transform 1s ease;
  background-color: #FFF0E0; /* Light orange background */
  box-sizing: border-box; /* Ensure padding is included in width */
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.careers.visible {
  opacity: 1;
  transform: translateY(0);
}

.careers h2 {
  font-size: 3vw;
  margin-bottom: 40px;
  color: #000;
}

.careers p {
  font-size: 1.1em;
}

/* Custom careers message styling */
.custom-careers-message {
  width: 90%;
  max-width: 1000px;
  margin: 40px auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 30px;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
}

.custom-careers-message h3 {
  color: #03045E;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 10px;
}

.custom-careers-message h3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #126d8e;
}

.careers-content {
  padding: 10px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 20px 0;
}

.no-positions {
  color: #555;
  margin-bottom: 15px;
}

.check-back {
  color: #555;
}

.careers-footer {
  text-align: right;
  margin-top: 20px;
}

.powered-by {
  color: #999;
  font-size: 0.9em;
}

.powered-by a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.powered-by a:hover {
  color: #126d8e;
}

.careers-loading {
  width: 90%;
  max-width: 1000px;
  margin: 40px auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  text-align: center;
  box-sizing: border-box;
}

/* Keep the original BambooHR styling improvements */
#BambooHR {
  width: 90% !important;
  max-width: 1000px !important;
  margin: 40px auto !important;
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08) !important;
  padding: 30px !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

#BambooHR h2,
#BambooHR .BambooHR-ATS-board > h2 {
  color: #03045E !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  margin-bottom: 25px !important;
  position: relative !important;
  padding-bottom: 10px !important;
}

#BambooHR h2:after,
#BambooHR .BambooHR-ATS-board > h2:after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 60px !important;
  height: 3px !important;
  background-color: #126d8e !important;
}

#BambooHR, 
.BambooHR-ATS-board,
.BambooHR-ATS-Department-List,
.BambooHR-ATS-Location-List,
.BambooHR-ATS-Jobs-List {
  background-color: white !important;
}

/* Enhanced responsive adjustments */
@media (max-width: 768px) {
  .careers {
    padding: 60px 15px;
    width: 100%;
    max-width: 100%;
    margin: 40px 0 0 0;
  }
  
  .careers h2 {
    font-size: 30px;
  }
  
  .custom-careers-message {
    width: 100%;
    margin: 30px auto;
    padding: 20px;
  }
  
  .custom-careers-message h3 {
    font-size: 24px;
  }
  
  #BambooHR {
    width: 100% !important;
    margin: 30px auto !important;
    padding: 20px !important;
  }
  
  .careers-loading {
    width: 100%;
    padding: 30px 20px;
  }
  
  .careers-content {
    padding: 10px;
  }
}
/* src/LeadershipTeam.css */
.leadership-team {
    margin: 80px 20px 80px 20px;
    text-align: center;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .leadership-team.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .leadership-team h2 {
    font-size: 3vw;
    margin-bottom: 70px;
  }
  
  .team-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .team-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .team-member {
    width: 260px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, opacity 0.8s ease;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .leadership-team.visible .team-member {
    opacity: 1;
    transform: translateY(0);
  }
  
  .team-member:hover {
    transform: translateY(-5px);
  }
  
  .team-member:hover .member-image img {
    filter: contrast(1.1) brightness(1.05);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
  }
  
  .member-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
  }
  
  .member-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: skewX(-25deg);
    z-index: 1;
    transition: all 0.75s;
    opacity: 0;
  }
  
  .team-member:hover .member-image::before {
    animation: shimmer 1.5s;
    opacity: 1;
  }
  
  @keyframes shimmer {
    0% {
      left: -100%;
    }
    100% {
      left: 200%;
    }
  }
  
  .member-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all 0.5s ease;
  }
  
  .member-info {
    padding: 20px;
    text-align: left;
  }
  
  .member-info h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
    color: #03045E;
  }
  
  .post-nominals {
    display: block;
    font-size: 14px;
    color: #666;
    margin-top: 2px;
  }
  
  .member-info h4 {
    margin: 0 0 15px 0;
    font-size: 14px;
    color: #000;
  }
  
  .bio-toggle {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 8px 0;
    text-align: left;
    font-size: 14px;
    color: #03045E;
    cursor: pointer;
    margin-bottom: 10px;
    transition: color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
  }
  
  .arrow {
    font-size: 10px;
    margin-left: 5px;
  }
  
  .bio-toggle:hover {
    color: #126d8e;
  }
  
  .bio-toggle.expanded {
    color: #126d8e;
    font-weight: 500;
  }
  
  /* Full-width biography section (desktop) */
  .full-width-bio {
    width: 100%;
    margin: 20px 0;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  /* Mobile biography styling */
  .mobile-bio {
    width: 100%;
    margin: 0;
    padding: 0 20px 20px 20px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  .mobile-bio .bio-content {
    margin-top: 0;
    padding: 15px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .bio-content {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 25px;
    text-align: left;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #03045E;
  }
  
  .bio-content h3 {
    color: #03045E;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  .bio-content p {
    font-size: 15px;
    line-height: 1.6;
    margin: 0;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .leadership-team h2 {
      font-size: 30px;
    }
    
    .team-member {
      width: 100%;
      max-width: 320px;
    }
    
    /* Disable hover effect on mobile */
    .team-member:hover {
      transform: none;
    }
    
    /* Make bio content in mobile view more compact */
    .mobile-bio .bio-content {
      padding: 15px;
    }
  }
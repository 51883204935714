/* src/ContactUs.css */
.contact-us {
    padding: 100px 20px;
    margin: 0;
    text-align: center;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease, transform 1s ease;
    background-color: #f8f9fa;
  }
  
  .contact-us.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .contact-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .contact-title {
    font-size: 3vw;
    margin-bottom: 40px;
    color: #03045E;
    font-weight: 700;
  }
  
  .contact-content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  
  .contact-info {
    flex: 1;
    text-align: left;
    padding-right: 20px;
  }
  
  .contact-info p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 30px;
    color: #333;
    text-align: justify;
    hyphens: auto;
  }
  
  .locations-title {
    font-size: 1.3em;
    color: #03045E;
    margin: 20px 0 15px 0;
    position: relative;
    padding-bottom: 8px;
  }
  
  .locations-title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    background-color: #126d8e;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-detail {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
  }
  
  .contact-detail svg {
    margin-right: 15px;
    min-width: 24px;
    color: #03045E;
    margin-top: 3px;
  }
  
  .address-info {
    display: flex;
    flex-direction: column;
  }
  
  .address-label {
    font-weight: 600;
    color: #126d8e;
    margin-bottom: 3px;
    font-size: 1.05em;
  }
  
  .contact-detail span {
    font-size: 1em;
    line-height: 1.5;
  }
  
  .contact-form-container {
    flex: 1.5;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    padding: 30px;
    text-align: left;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-size: 0.9em;
    font-weight: 600;
    color: #444;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 1em;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #03045E;
    box-shadow: 0 0 0 2px rgba(3, 4, 94, 0.1);
  }
  
  .submit-btn {
    background-color: #03045E;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 25px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 10px;
    align-self: flex-start;
  }
  
  .submit-btn:hover {
    background-color: #040580;
    transform: translateY(-2px);
  }
  
  .submit-btn:active {
    transform: translateY(0);
  }
  
  .form-error {
    background-color: #fff0f0;
    border-left: 4px solid #ff3b30;
    padding: 12px 15px;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  
  .form-error p {
    color: #d03b30;
    margin: 0;
    font-size: 0.9em;
  }
  
  .form-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 20px;
  }
  
  .form-success svg {
    color: #28a745;
    margin-bottom: 20px;
  }
  
  .form-success h3 {
    font-size: 1.5em;
    color: #28a745;
    margin-bottom: 15px;
  }
  
  .form-success p {
    color: #666;
    margin-bottom: 25px;
    font-size: 1.1em;
  }
  
  .new-message-btn {
    background-color: #03045E;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 25px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .new-message-btn:hover {
    background-color: #040580;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contact-title {
      font-size: 30px;
    }
    
    .contact-content {
      flex-direction: column;
    }
    
    .contact-info {
      padding-right: 0;
      margin-bottom: 30px;
    }
    
    .submit-btn {
      width: 100%;
    }
  }
.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.hero-section {
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
  padding: 100px 20px 10px 20px;
  margin-bottom: 75px;
  position: relative;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: radial-gradient(#03045E 1px, transparent 1px);
  background-size: 20px 20px;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

#BambooHR {
  width: 60%;
  margin: 5% 0 0 22%;
  /* height: 500px; */
}

/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@media (max-width: 768px) {
  .scroll-arrow {
    width: 30px;
    height: 30px;
    bottom: 20px;
    right: 20px;
  }
}

/* Global h2 styling for consistency */
h2 {
  font-size: 3vw;
  margin-bottom: 40px;
  font-weight: 700;
  color: #03045E;
}

@media (max-width: 768px) {
  h2 {
    font-size: 24px;
  }
}
/* src/Logo.css */
.logo-container {
  text-align: center;
  animation: fadeIn 1.2s ease-in-out;
}

.logo {
  width: 88%;
  max-width: 1000px;
  padding-bottom: 100px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.02);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
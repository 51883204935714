/* src/Footer.css */
.footer {
  background-color: #03045E; /* Dark blue color */
  color: white;
  padding: 40px 20px;
  width: 100%;
  margin-top: auto;
  box-sizing: border-box; /* Ensure padding is included in width */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  box-sizing: border-box;
}

.footer p {
  margin: 8px 0;
  font-size: 14px;
  line-height: 1.5;
}

.footer-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.footer-links {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-links a {
  color: #CAF0F8;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: white;
  text-decoration: underline;
}

.footer-social {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.linkedin-link {
  display: flex;
  align-items: center;
  color: #CAF0F8;
  text-decoration: none;
  font-size: 15px;
  transition: color 0.3s ease;
  gap: 8px;
}

.linkedin-link:hover {
  color: white;
}

.linkedin-link svg {
  transition: transform 0.3s ease;
}

.linkedin-link:hover svg {
  transform: scale(1.1);
}

.footer-locations {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.locations-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  max-width: 800px;
}

.footer-legal {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  margin-top: 10px;
}

.legal-links {
  margin-top: 10px;
}

.legal-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 13px;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: white;
  text-decoration: underline;
}

.divider {
  margin: 0 10px;
  color: rgba(255, 255, 255, 0.4);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
    width: 100vw; /* Use viewport width */
    max-width: 100%; /* Ensure it doesn't exceed viewport */
    position: relative; /* Help with positioning */
    left: 0; /* Align properly */
    right: 0;
  }
  
  .footer-content {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  
  .footer-links {
    gap: 20px;
  }
  
  .footer-locations p {
    font-size: 13px;
    word-wrap: break-word; /* Handle long text */
    padding: 0 10px;
  }
  
  .locations-container {
    max-width: 100%;
    padding: 0 5px;
  }
}
/* src/ScrollArrow.css */
.scroll-arrow {
    position: absolute;
    bottom: 10px;
    right: 30px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    animation: bounce 2.8s infinite;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .scroll-arrow svg {
    fill: #03045E;
    width: 100%;
    height: 100%;
  }
  
  .scroll-arrow:hover {
    opacity: 1;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  @media (max-width: 768px) {
    .scroll-arrow {
      width: 30px;
      height: 30px;
      bottom: 20px;
      right: 20px;
    }
  }
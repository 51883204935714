/* src/OurScience.css */
.our-science {
    background-color: #126d8e;
    color: white;
    padding: 80px 20px;
    text-align: center;
    transition: opacity 1s ease, transform 1s ease;
}
  
.our-science.visible {
    opacity: 1;
    transform: translateY(0);
}
  
.science-container {
    max-width: 1200px;
    margin: 0 auto;
}
  
.science-intro {
    max-width: 800px;
    margin: 0 auto 60px auto;
    line-height: 1.6;
    font-size: 1.1em;
}

/* Fade element styling */
.fade-element {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.fade-element.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Title specific animation */
.science-title {
    transition-delay: 0.1s;
}

/* Intro specific animation */
.science-intro {
    transition-delay: 0.3s;
}

/* Staggered delays for rows */
.science-row:nth-child(1) {
    transition-delay: 0.5s;
}

.science-row:nth-child(2) {
    transition-delay: 0.7s;
}

.science-row:nth-child(3) {
    transition-delay: 0.9s;
}
  
.science-content {
    display: flex;
    flex-direction: column;
    gap: 80px;
}
  
.science-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}
  
.science-row:nth-child(even) {
    flex-direction: row-reverse;
}
  
.our-science.visible .fade-item {
    opacity: 1;
    transform: translateY(0);
}
  
.science-text {
    flex: 1;
    text-align: left;
}
  
.science-text h3 {
    color: #CAF0F8;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5em;
}
  
.science-text p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}
  
.science-image {
    flex: 1;
    max-width: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.2);
}
  
.science-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}
  
.science-image:hover img {
    transform: scale(1.05);
}
  
/* Image gallery at the bottom */
.science-gallery {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
  
.gallery-item {
    position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
  
.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}
  
.gallery-item:hover img {
    transform: scale(1.05);
}
  
.our-science h2 {
    color: #CAF0F8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .our-science h2 {
        font-size: 30px;
    }
    
    .science-row, .science-row:nth-child(even) {
        flex-direction: column;
    }
    
    .science-image {
        width: 100%;
        max-width: 100%;
        margin-bottom: 30px;
    }
    
    .science-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 600px) {
    .science-gallery {
        grid-template-columns: 1fr;
    }
}
/* src/Tagline.css */
.tagline {
  margin: 40px 0 30px 0;
  text-align: center;
  animation: fadeIn 1.4s ease-in-out;
  animation-delay: 0.3s;
  animation-fill-mode: both;
}

.tagline h1 {
  font-size: 2.5vw;
  color: #03045E;
  font-weight: 700;
  line-height: 1.4;
  max-width: 800px;
  margin: 0 auto 20px auto;
  position: relative;
}

.tagline img {
  margin: 25px 0 25px 0;
  width: 150px;
  transition: transform 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .tagline h1 {
    font-size: 24px;
  }
}